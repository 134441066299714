// import {startCase} from "lodash";
function getTitle (vm) {
    const { title } = vm.$options
    if (title) {
      return typeof title === 'function'
        ? title.call(vm)
        : title
    } 
  }
  export default {
    created () {
      const title = getTitle(this);
      let url;
      if(this.$store?.getters?.getIsTenant)
      {
        url = this.$store?.getters?.getLoginTenantDetails?.logo_url || this.$store?.getters?.getThemeLogo
      }
      else if(this.$store?.getters?.getIsClient)
      {
        url = this.$store?.getters?.getLogo || this.$store?.getters?.getClientLogo || this.$store?.getters?.getLoginTenantDetails?.logo_url || this.$store?.getters?.getThemeLogo
      }
      else if(this.$store?.getters?.getIsCandidate)
      {
        url =  this.$store?.getters?.getFavIcon
      }
      let tenant = {
        // domain_name: "Neotas",
        ...{ 
          domain_name: this.$store?.getters?.getClientName || this.$store?.getters?.getLoginTenantDetails?.domain_name,
          // logo_url: this.$store?.getters?.getThemeLogo || this.$store?.getters?.getLoginTenantDetails?.logo_url,
          logo_url: url
        },
      }
      if (title) {
        document.title = title;
        if (tenant?.domain_name) 
          // document.title += ' | ' + startCase(tenant.domain_name);
          document.title += ' | ' + tenant.domain_name;
      } else {
        if (tenant?.domain_name) 
          // document.title = startCase(tenant.domain_name);
          document.title = tenant.domain_name;
      }
      if (tenant?.logo_url) document.querySelector("link[rel~='icon']").href = tenant?.logo_url;
      else if (this.$store?.getters?.getLoginScreenInfo) document.querySelector("link[rel~='icon']").href = `${process.env.VUE_APP_API_BASE_URL}/favicon.ico`;
    },
  }